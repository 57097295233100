import Vue from 'vue'
import _ from 'lodash'
import { Base64 } from 'js-base64'
// import this from '@/event-bus'
import store from '@/store'

export default {
  data() {
    return {
      loading: true,
      filterOnURL: true,
      columns: [],
      rows: [],
      settings: {},
      filterPromises: [],

      // для фильтров
      filters: {},
      defaultFilters: {
        perPage: 20,
        page: 1,
      },
    }
  },
  methods: {
    loadingPromise(promises, callback = null) {
      const onlyPromises = []
      this.loading = true

      // Если это функции на обновление фильтров и тп, то запускаем их
      promises.forEach(fn => {
        if (typeof fn === 'function') {
          onlyPromises.push(fn())
        } else {
          onlyPromises.push(fn)
        }
      })

      Promise.all(onlyPromises)
        .then(() => {
          this.loading = false
          if (callback !== null) {
            callback()
          }
        })
    },

    // Методы для фильтров, пагинации и другое.
    setDefaultFilter(filterObject, callback) {
      Vue.set(this, 'defaultFilters', _.merge(this.defaultFilters, filterObject))
      if (typeof callback === 'function') {
        callback()
      }
    },
    setFilterToDefault(filterUpdateEvent = false) {
      Vue.set(this, 'filters', _.cloneDeep(this.defaultFilters))
      if (filterUpdateEvent) {
        this.doPushEventFilterUpdated()
      }
    },
    doUpdateFilter(filtersObject) {
      const originalFilters = _.cloneDeep(this.filters)
      Vue.set(this, 'filters', _.merge(originalFilters, filtersObject))
      this.doPushEventFilterUpdated()
    },
    doApplyFilters() {
      const originalFilters = _.cloneDeep(this.filters)
      Vue.set(this, 'filters', _.merge(originalFilters, { page: 1 }))

      if (this.filterOnURL) {
        try {
          const query = { ...this.$route.query }
          let allowRouteChange = false

          if (!_.isEqual(this.filters, this.defaultFilters)) {
            allowRouteChange = true
            query.filter = Base64.encode(JSON.stringify(this.filters))
          } else if (Object.prototype.hasOwnProperty.call(query, 'filter')) {
            allowRouteChange = true
            delete query.filter
          }

          if (allowRouteChange) {
            this.$router.replace({ query })
          }
        } catch (e) {
          console.log(e)
        }
      }

      this.doPushEventFilterUpdated()
    },
    doResetFilters() {
      const query = { ...this.$route.query }
      delete query.filter
      this.$router.replace({ query })

      this.setFilterToDefault(true)
    },
    doPushEventFilterUpdated() {
      this.$emit('filters:update', true)
    },
  },
  beforeMount() {
    this.setFilterToDefault()

    this.$on('filters:update', () => this.loadingPromise(this.filterPromises))
    this.$on('table:refresh', () => this.loadingPromise(this.filterPromises))
  },
  mounted() {
    const query = { ...this.$route.query }
    if (Object.prototype.hasOwnProperty.call(query, 'filter')) {
      this.doUpdateFilter(JSON.parse(Base64.decode(query.filter)))
    }
  },
  beforeDestroy() {
    // Чистим заголовок, если он был поставлен, забываем событие фильтров, если они были
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
    this.$off('filters:update')
  },
}
