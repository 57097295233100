<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>

    <div v-else>
      <div class="d-flex justify-content-end">
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form class="py-1">

            <b-form-group
              label="Имя"
              label-for="dropdown-form-name"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-name"
                v-model="filters.name"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Email"
              label-for="dropdown-form-email"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-email"
                v-model="filters.email"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>

        <router-link :to="{name: 'users-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>

      </div>

      <data-table
        :columns="columns"
        :rows.sync="rows"
        :settings="settings"
        :dev-logs="false"
        @updateFilter="doUpdateFilter"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <div class="text-center">
            {{ props.column.label }}
          </div>
        </template>

        <template v-slot:table-row="item">
          <div
            v-if="item.column.field === 'name'"
            class="text-left"
          >
            <b-avatar
              size="32"
              :text="computedAvatarText(item.row.name)"
              :src="item.row.photo ? `${$imageBaseUrl}${item.row.photo}` : null"
              class="mr-1"
            />
            {{ item.row.name ? item.row.name : '—' }}
          </div>
          <div
            v-else-if="item.column.field === 'actions'"
            class="text-center"
          >
            <router-link :to="{name: 'users-update', params: {id: item.row.id}}">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning"
                />
              </b-button>
            </router-link>

            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="objectDelete(item.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ item.formattedRow[item.column.field] }}
          </div>
        </template>
      </data-table>
    </div>
  </b-card>
</template>
<script>
import {
  BAvatar,
  BButton,
  BButtonGroup,
  BFormInput,
  BCard,
  BDropdown,
  BDropdownForm,
  BFormGroup,
  BFormSelect,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import _ from 'lodash'
import DataTable from '@/components/DataTable.vue'
import axiosIns from '@/libs/axios'
import { rowsObjectConverter, settingsObjectConverter } from '@/helpers/dataTableHelper'
import dataTableMixin from '@/mixins/dataTableMixin'

export default {
  name: 'CategoriesList',
  components: {
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BButton,
    BButtonGroup,
    BFormSelect,
    BSpinner,
    DataTable,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    dataTableMixin,
  ],
  metaInfo: {
    title: 'Список пользователей',
  },
  data() {
    return {
      loading: true,
      filterOnURL: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'ФИО',
          field: 'name',
        },
        {
          label: 'E-mail',
          field: rowObj => rowObj.email ?? '—',
        },
        {
          label: 'Действия',
          field: 'actions',
        },
      ],
    }
  },
  beforeMount() {
    Promise.all([
      this.doLoadUsers(),
    ]).then(() => {
      this.loading = false
    })

    // Пушим промисы фильтров, чтоб вызывать методы
    this.filterPromises.push(() => this.doLoadUsers())
  },
  methods: {
    doLoadUsers() {
      return axiosIns({
        method: 'GET',
        url: '/users/list',
        params: _.merge(this.filters),
      })
        .then(response => {
          this.$set(this, 'settings', settingsObjectConverter(response.data.data))
          this.$set(this, 'rows', rowsObjectConverter(response.data.data))
        })
    },
    objectDelete(id) {
      this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axiosIns({
              method: 'DELETE',
              url: `/users/${id}`,
            })
              .then(() => {
                this.doLoadUsers()
                this.$swal({
                  icon: 'success',
                  title: 'Удалено!',
                  text: '',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
    },
    computedAvatarText(name) {
      const arr = []
      const arrName = name.split(' ')
      arrName.forEach(element => {
        arr.push(element.substr(0, 1))
      })
      return arr.slice(0, 2)
        .join('')
    },
  },
}
</script>
