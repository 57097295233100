var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mb-2"},[_vm._t("header-button")],2),_c('vue-good-table',{attrs:{"mode":"remote","style-class":"table b-table table-hover table-sm table-bordered","columns":_vm.columns,"rows":_vm.rows,"sort-options":{ enabled: false },"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm
    },"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: false,
    },"pagination-options":{
      enabled: true,
    }},on:{"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(x,slotName){return {key:slotName,fn:function(context){return [_vm._t(slotName,null,null,context)]}}})],null,true)},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[_vm._v(" Опять ничего нет ¯\\_(ツ)_/¯ ")])]),_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Отображено "+_vm._s(_vm.settings.from)+" — "+_vm._s(_vm.settings.to)+", всего "+_vm._s(_vm.settings.total)+" ")])]),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.settings.currentPage,"total-rows":_vm.settings.total,"per-page":_vm.settings.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}])})],1)])])],2),(_vm.devLogs)?_c('prism',{staticClass:"rounded mt-1"},[_vm._v(" "+_vm._s(_vm.log.join('\n'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }